import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import SelectInputs from 'src/components/inputs/SelectInputs';

const initialValue = {
  serviceName: '',
  category: '',
  duration: '',
  price: '',
  serviceFor: '',
};

const initialFormValidation = {
  serviceNameValidation: false,
  priceValidation: false,
  serviceForValidation: false,
};

const serviceOptions = [
  {
    name: 'Male',
  },
  {
    name: 'Female',
  },
  {
    name: 'Both',
  },
];

const AddService = () => {
  const { createService, activeBranchId, setShowMessage } = useMain();

  const [formData, setFormData] = useState(initialValue);
  const [formValidation, setFormValidation] = useState(initialFormValidation);

  const [loading, setLoading] = useState(false);

  const serviceNameValidation = !formData.serviceName.trim();
  const priceValidation = !formData.price || !(Number(formData.price) > 0);
  const serviceForValidation = !formData.serviceFor;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const { serviceName, price, serviceFor } = formData;
    const validation = {
      serviceNameValidation: !serviceName.trim(),
      priceValidation: !price || !(Number(price) > 0),
      serviceForValidation: !serviceFor,
    };

    setFormValidation((pre) => ({ ...pre, ...validation }));

    if (Object.values(validation).some((item) => item)) return;

    try {
      setLoading(true);
      const res = await createService(
        JSON.stringify({
          ...formData,
          branchDetails: activeBranchId,
        }),
      );
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Successfully created service',
          messageType: 'success',
        });
        setFormData(initialValue);
        setFormValidation((pre) => ({ ...initialFormValidation }));
      } else {
        setShowMessage({ message: res.message || 'Error creating service', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Error creating service', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Add Service" description="this is add Service">
      <DashboardCard title="Add Service">
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Service Name'}
                name={'serviceName'}
                value={formData?.serviceName}
                handleChange={handleChange}
                type={'text'}
                fieldValidation={serviceNameValidation && formValidation.serviceNameValidation}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={true}
                title={'Category Name'}
                name={'category'}
                value={formData?.category}
                handleChange={handleChange}
                type={'text'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Price'}
                name={'price'}
                // placeholder="0"
                value={formData?.price}
                handleChange={handleChange}
                type={'number'}
                fieldValidation={priceValidation && formValidation.priceValidation}
              />
            </Grid>
          </Grid>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Duration (in minutes)'}
                name={'duration'}
                // placeholder="0"
                value={formData?.duration}
                handleChange={handleChange}
                type={'number'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectInputs
                title={'Services For'}
                options={serviceOptions}
                name={'serviceFor'}
                value={formData?.serviceFor}
                handleChange={handleChange}
                optionTitle={'name'}
                optionValue={'name'}
                fieldValidation={serviceForValidation && formValidation.serviceForValidation}
              />
            </Grid>
          </Grid>
        </Box>

        <div style={{ width: '200px', margin: '3rem auto' }}>
          {loading ? (
            <ShowLoader />
          ) : (
            <Button
              variant="contained"
              fullWidth
              price="primary"
              target="_blank"
              sx={{
                background: '#6174DD',
              }}
              onClick={handleSubmit}
            >
              Add Service
            </Button>
          )}
        </div>
      </DashboardCard>
    </PageContainer>
  );
};

export default AddService;
