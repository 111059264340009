import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Avatar,
  Switch,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import TableCells from 'src/components/Tables/TableCells';
import CheckboxInputs from 'src/components/inputs/CheckboxInputs';
import TextInputs from 'src/components/inputs/TextInputs';
import RadioInputs from 'src/components/inputs/RadioInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import CustomShowLoader from 'src/components/CustomComponents/CustomShowLoader';
import TimeSlotSelector from 'src/components/inputs/TimeSlotSelector';

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

const incentive_types = ['%', 'INR'];

const FieldData = ({ data, setData, getData }) => {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false);
  const [accountStatus, setAccountStatus] = useState(null);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedStaffs, setCheckedStaffs] = useState([]);
  const [updatedStaffData, setUpdatedStaffData] = useState({});
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [displayImg, setDisplayImg] = useState('');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);

  const { deleteStaff, updateStaff, setShowMessage, activeBranchId, changeStaffAccountStatus } =
    useMain();

  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setCheckedStaffs([]);
    } else {
      setCheckedStaffs(data);
    }
    setIsAllChecked(!isAllChecked);
  };

  const handleSignleCheckboxChange = (staffId) => {
    if (checkedStaffs.length && checkedStaffs.some((check) => check._id === staffId)) {
      setCheckedStaffs((prev) => prev.filter((check) => check._id !== staffId));
    } else {
      const checked = data.filter((item) => item._id === staffId);
      setCheckedStaffs((prev) => [...prev, ...checked]); // Create a new array
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedStaffData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleWorkingHrsInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedStaffData((prev) => ({ ...prev, workingHrs: { ...prev.workingHrs, [name]: value } }));
  };

  const handleEmergencyDetailsInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedStaffData((pre) => ({
      ...pre,
      emergencyDetails: { ...pre.emergencyDetails, [name]: value },
    }));
  };

  const handleDisplayImageChange = (e) => {
    setImage(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setDisplayImg(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleOpenStatusDialogue = (accountStatus, selectedItemId) => {
    setSelectedItemId(selectedItemId);
    setAccountStatus(accountStatus);
    setOpenConfirmDialogue(true);
  };

  const handleCloseStatusDialogue = () => {
    setSelectedItemId(null);
    setAccountStatus(null);
    setOpenConfirmDialogue(false);
  };

  const handleConfirmStatus = async () => {
    setChangeStatusLoading(true);
    const queryParams = `?branchId=${activeBranchId}`;
    try {
      const res = await changeStaffAccountStatus(selectedItemId, queryParams, {
        activeStatus: !accountStatus,
      });
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Account status changed',
          messageType: 'success',
        });
        setSelectedItemId(null);
        setAccountStatus(null);
        setData([]);
        getData();
      } else {
        setShowMessage({
          message: res.message || 'Account Status not changes',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setChangeStatusLoading(false);
    }
  };

  const handleUpdate = async (item) => {
    setDisplayImg(item?.displayImg);
    setSelectedItemId(item._id);
    setUpdatedStaffData({
      ...updatedStaffData,
      name: item?.name,
      phone: item?.phone,
      address: item?.address,
      mail: item?.mail,
      department: item?.department,
      salary: item?.salary,
      userType: item?.userType,
      emergencyDetails: item?.emergencyDetails,
      workingHrs: item?.workingHrs,
      dateOfJoining: item?.dateOfJoining,
      dob: item?.dob,
      incentive: item?.incentive,
      incentiveType: item?.incentiveType,
      // targetAmount: item?.targetAmount,
      target: item?.target,
      // new line added to show previous gender
      gender: item?.gender,
    });
    setOpenDialogue(true);
  };


  const handleUpdateDisplayImg = async () => {
    const updateDisplayImg = new FormData();
    updateDisplayImg.append('images', image);
  };

  const handleUpdateStaffData = async () => {
    try {
      setLoading(true);
      const res = await updateStaff(
        selectedItemId,
        updatedStaffData,
        `?branchId=${activeBranchId}`,
      );
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Staff details updated successfully',
          messageType: 'success',
        });
        setData([]);
        getData();
      } else {
        setShowMessage({
          message: res.message || 'Staff details not updated',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(false);
    }
  };

  const handleDeleteField = async (id) => {
    try {
      const res = await deleteStaff(id, `?branchId=${activeBranchId}`);
      if (res?.statusCode === 200) {
        setData(data?.filter((data) => data?._id !== id));
        setShowMessage({
          message: res.message || 'Staff details deleted successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({
          message: res.message || 'Staff details not deleted',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
    }
  };

  useEffect(() => {
    setIsAllChecked((data?.length && checkedStaffs?.length === data?.length) || false);
  }, [checkedStaffs?.length, data?.length]);

  const handleIncentiveTypeChange = (event, newIncentiveType) => {
    if (newIncentiveType !== null) {
      setUpdatedStaffData({ ...updatedStaffData, incentiveType: newIncentiveType });
    }
  };

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            <th style={{ width: '20%' }}>
              <CheckboxInputs
                type={'Heading'}
                isAllChecked={isAllChecked}
                handleAllCheckboxChange={handleAllCheckboxChange}
              />
            </th>
            {[
              'Name',
              'Contact Number',
              'Gender',
              'Email',
              'Salary',
              'Address',
              'Join Date',
              'Department',
              'Active',
              'Actions',
            ].map((h, index) => {
              return (
                <th key={index} style={{ width: '100%' }}>
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => {
            return (
              <tr key={item._id}>
                <td style={{ width: '20%' }}>
                  <CheckboxInputs
                    type={'content'}
                    handleSignleCheckboxChange={handleSignleCheckboxChange}
                    checkedItems={checkedStaffs}
                    fieldId={item?._id}
                  />
                </td>
                <td style={{ width: '100%' }}>{TrimData(item?.name)}</td>
                <td style={{ width: '100%' }}>{item?.phone}</td>
                <td style={{ width: '100%' }}>{item?.gender}</td>
                <td style={{ width: '100%' }}>{item?.mail}</td>
                <td style={{ width: '100%' }}>{item?.salary}</td>
                <td style={{ width: '100%' }}>{item?.address}</td>
                <td style={{ width: '100%' }}>{item?.dateOfJoining}</td>
                <td style={{ width: '100%' }}>{item?.department}</td>
                <td style={{ width: '100%' }}>
                  <Switch
                    checked={item?.active}
                    onClick={() => handleOpenStatusDialogue(item?.active, item._id)}
                  />
                </td>
                <td style={{ width: '100%' }}>
                  <TableCells
                    type="button"
                    handleDeleteField={handleDeleteField}
                    handleUpdate={() => handleUpdate(item)}
                    // handleDetails={()=>{}}
                    field={item?._id}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* ---------------------- Staff Details Dialog ---------------- */}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="xl">
        <DialogTitle>Staff Details</DialogTitle>
        <DialogContent style={{ width: '1300px' }}>
          <DialogContentText>Here you can update Staff details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Name'}
                  name={'name'}
                  value={updatedStaffData?.name}
                  handleChange={handleInputChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Date of Birth'}
                  name={'dob'}
                  value={updatedStaffData?.dob}
                  handleChange={handleInputChange}
                  type={'date'}
                  style={{ backgroundColor: '#f4f4f4', color: '#777', cursor: 'not-allowed' }}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Contact Number'}
                  name={'phone'}
                  value={updatedStaffData?.phone}
                  handleChange={handleInputChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Email Address'}
                  name={'mail'}
                  value={updatedStaffData?.mail}
                  handleChange={handleInputChange}
                  type={'email'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TimeSlotSelector
                        required={false}
                        title={'Shift Timing'}
                        name={'start'}
                        value={updatedStaffData?.workingHrs?.start}
                        handleChange={handleWorkingHrsInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: '26px' }}>
                      <TimeSlotSelector
                        required={false}
                        title={'  '}
                        name={'end'}
                        value={updatedStaffData?.workingHrs?.end}
                        handleChange={handleWorkingHrsInputChange}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Monthly Salary'}
                  name={'salary'}
                  value={updatedStaffData?.salary}
                  handleChange={handleInputChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Emergency Contact Number'}
                  name={'contactNo'}
                  value={updatedStaffData?.emergencyDetails?.contactNo}
                  handleChange={handleEmergencyDetailsInputChange}
                  type={'number'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Emergency Contact Person'}
                  name={'name'}
                  value={updatedStaffData?.emergencyDetails?.name}
                  handleChange={handleEmergencyDetailsInputChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Address'}
                  name={'address'}
                  value={updatedStaffData?.address}
                  handleChange={handleInputChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                {/* <RadioInputs
                  title={'Gender'}
                  name={'gender'}
                  value={updatedStaffData?.gender}
                  handleChange={handleInputChange}
                  options={[
                    { title: 'M', value: 'Male' },
                    { title: 'F', value: 'Female' },
                  ]}
                  required={false}
                /> */}
                <RadioInputs
                  title={'Gender'}
                  name={'gender'}
                  value={updatedStaffData?.gender}
                  handleChange={handleInputChange}
                  options={[
                    { title: 'M', value: 'Male' },
                    { title: 'F', value: 'Female' },
                  ]}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'User Type'}
                  name={'userType'}
                  value={updatedStaffData?.userType}
                  handleChange={handleInputChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Department'}
                  name={'department'}
                  value={updatedStaffData?.department}
                  handleChange={handleInputChange}
                  type={'text'}
                  required={false}
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Incentive'}
                  name={'incentive'}
                  value={updatedStaffData?.incentive}
                  handleChange={handleInputChange}
                  type={'number'}
                  placeholder={'0'}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={{ marginBottom: '6px', fontWeight: 'bold', color: 'black' }}>Incentive Type</div>
                {/* <Select
                  name="incentiveType"
                  value={updatedStaffData?.incentiveType}
                  onChange={handleInputChange}
                  fullWidth
                >
                  {['%', 'INR']?.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select> */}
                <ToggleButtonGroup
                  value={updatedStaffData?.incentiveType}
                  exclusive
                  onChange={handleIncentiveTypeChange}
                  aria-label="incentive type"
                  fullWidth
                  sx={{
                    borderRadius: '4px',
                    height: '50px',
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  {incentive_types.map((type) => (
                    <ToggleButton
                      key={type}
                      value={type}
                      aria-label={type}
                      sx={{
                        color: '#000',
                        backgroundColor: '#e0e0e0',
                        height: '3.4rem',
                        '&.Mui-selected': {
                          backgroundColor: '#6174DD',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#6174DD',
                          },
                        },
                        '&:not(.Mui-selected)': {
                          '&:hover': {
                            backgroundColor: '#d0d0d0',
                          },
                        }
                      }}
                    >
                      {type}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Target Amount'}
                  name={'target'}
                  // value={updatedStaffData?.targetAmount}
                  value={updatedStaffData?.target}
                  handleChange={handleInputChange}
                  type={'number'}
                  placeholder={'0'}
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <TextInputs
                  title={'Date of Joining'}
                  name={'dateOfJoining'}
                  value={updatedStaffData?.dateOfJoining}
                  handleChange={handleInputChange}
                  type={'date'}
                  max={new Date().toISOString().split('T')[0]}
                  style={{ backgroundColor: '#f4f4f4', color: '#777', cursor: 'not-allowed' }}
                  required={false}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={8}
                sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                spacing={2}
              >
                <Avatar
                  src={displayImg}
                  alt={'image'}
                  sx={{ width: 35, height: 35, alignSelf: 'center' }}
                />
                <TextInputs
                  title={'Update Photo'}
                  name={'displayImg'}
                  handleChange={handleDisplayImageChange}
                  type={'file'}
                  required={false}
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'primary',
                    color: '#fff',
                    align: 'center',
                  }}
                  onClick={handleUpdateDisplayImg}
                >
                  Update image
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleUpdateStaffData}
                >
                  update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirmDialogue} onClose={handleCloseStatusDialogue} maxWidth="md">
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {accountStatus ? 'Deactivate' : 'Activate'} this staff account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {changeStatusLoading ? (
            <CustomShowLoader size={34} />
          ) : (
            <>
              <Button onClick={handleCloseStatusDialogue}>Cancel</Button>
              <Button variant="contained" onClick={() => handleConfirmStatus(accountStatus)}>
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FieldData;