// import { Typography } from '@mui/material';
// import React from 'react';
// import Select from 'react-select';
// import { ReqField } from '../CustomComponents/CustomLoader';

// const TimeSlotSelector = ({ title, name, value, handleChange, required, fieldValidation }) => {
//     const generateTimeOptions = () => {
//         const options = [];
//         for (let hour = 0; hour < 24; hour++) {
//             for (let minute = 0; minute < 5; minute++) {
//                 let hours12 = hour % 12 || 12;
//                 let period = hour < 12 ? 'AM' : 'PM';
//                 let time = `${String(hour).padStart(2, '0')}:${String(minute * 5).padStart(2, '0')}`;
//                 let label = `${String(hours12).padStart(2, '0')}:${String(minute * 5).padStart(2, '0')} ${period}`;
//                 options.push({ value: time, label: label });
//             }
//         }
//         return options;
//     };

//     const timeOptions = generateTimeOptions();

//     const customStyles = {
//         control: (provided, state) => ({
//             ...provided,
//             minHeight: '3.3rem',
//             fontSize: '1rem',
//             borderColor: state.isFocused ? '#6174DD' : '#ccc',
//             boxShadow: state.isFocused ? '0 0 0 1px #6174DD' : null,
//             '&:hover': {
//                 borderColor: state.isFocused ? '#6174DD' : '#ccc'
//             }
//         }),
//         placeholder: (provided) => ({
//             ...provided,
//             fontSize: '1rem'
//         }),
//         option: (provided, state) => ({
//             ...provided,
//             backgroundColor: state.isSelected ? '#6174DD' : state.isFocused ? '#E0E7FF' : 'white',
//             color: state.isSelected ? 'white' : 'black',
//             '&:hover': {
//                 backgroundColor: '#6174DD',
//                 color: 'white'
//             }
//         }),
//         singleValue: (provided) => ({
//             ...provided,
//             color: 'black'
//         })
//     };

//     const formatTimeTo24hr = (selectedTime) => {
//         const [time, period] = selectedTime.split(' ');
//         let [hours, minutes] = time.split(':');
//         hours = parseInt(hours, 10);
//         minutes = parseInt(minutes, 10);

//         if (period === 'PM' && hours !== 12) {
//             hours += 12;
//         } else if (period === 'AM' && hours === 12) {
//             hours = 0;
//         }

//         return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
//     };

//     const formatTimeTo12hr = (time) => {
//         let [hours, minutes] = time.split(':');
//         hours = parseInt(hours, 10);
//         minutes = parseInt(minutes, 10);
//         const period = hours >= 12 ? 'PM' : 'AM';
//         hours = hours % 12 || 12;
//         return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
//     };

//     const handleChangeSelect = (selectedOption) => {
//         const formattedTime = selectedOption ? formatTimeTo24hr(selectedOption.label) : '';
//         handleChange({ target: { name, value: formattedTime } });
//     };

//     return (
//         <div style={{ fontFamily: 'Arial, sans-serif' }}>
//             <Typography
//                 variant="subtitle1"
//                 fontWeight={600}
//                 component="label"
//                 htmlFor={name}
//                 mb="5px"
//             >
//                 {title} {required !== false && <ReqField />}
//             </Typography>
//             <Select
//                 options={timeOptions}
//                 value={timeOptions.find(option => option.value === value)}
//                 onChange={handleChangeSelect}
//                 placeholder={`Select or search for ${title}`}
//                 isClearable
//                 styles={customStyles}
//             />
//             {fieldValidation && (
//                 <div style={{ color: 'red', fontSize: '0.875rem', marginTop: '0.25rem' }}>
//                     This field is required
//                 </div>
//             )}
//         </div>
//     );
// };

// export default TimeSlotSelector;

import { Typography } from '@mui/material';
import React from 'react';
import Select from 'react-select';
import { ReqField } from '../CustomComponents/CustomLoader';

const TimeSlotSelector = ({ title, name, value, handleChange, required, fieldValidation }) => {
    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 5) {
                let hours12 = hour % 12 || 12;
                let period = hour < 12 ? 'AM' : 'PM';
                let time = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
                let label = `${String(hours12).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
                options.push({ value: time, label: label });
            }
        }
        return options;
    };

    const timeOptions = generateTimeOptions();

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: '3.3rem',
            fontSize: '1rem',
            borderColor: state.isFocused ? '#6174DD' : '#ccc',
            boxShadow: state.isFocused ? '0 0 0 1px #6174DD' : null,
            '&:hover': {
                borderColor: state.isFocused ? '#6174DD' : '#ccc'
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '1rem'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#6174DD' : state.isFocused ? '#E0E7FF' : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: '#6174DD',
                color: 'white'
            }
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        })
    };

    const formatTimeTo24hr = (selectedTime) => {
        const [time, period] = selectedTime.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        minutes = parseInt(minutes, 10);

        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    const formatTimeTo12hr = (time) => {
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        minutes = parseInt(minutes, 10);
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
    };

    const handleChangeSelect = (selectedOption) => {
        const formattedTime = selectedOption ? formatTimeTo24hr(selectedOption.label) : '';
        handleChange({ target: { name, value: formattedTime } });
    };

    return (
        <div style={{ fontFamily: 'Arial, sans-serif' }}>
            <Typography
                variant="subtitle1"
                fontWeight={600}
                component="label"
                htmlFor={name}
                mb="5px"
            >
                {title} {required !== false && <ReqField />}
            </Typography>
            <Select
                options={timeOptions}
                value={timeOptions.find(option => option.value === value)}
                onChange={handleChangeSelect}
                // placeholder={`Select or search for ${title}`}
                isClearable
                styles={customStyles}
            />
            {fieldValidation && (
                <div style={{ color: 'red', fontSize: '0.875rem', marginTop: '0.25rem' }}>
                    This field is required
                </div>
            )}
        </div>
    );
};

export default TimeSlotSelector;
