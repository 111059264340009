import { baseUrl, headers, jwtHeaders, logoutUtil } from '../common/data';

export const fetchAllClients = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/client/getallclient/${branchId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      headers: jwtHeaders(),
      redirect: 'follow',
    },
  );
  if (response?.status === 401) {
    logoutUtil();
  }
  const resData = await response?.json();

  return resData;
};

export const getAllClient = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/client/getall/${branchId}`, {
    method: 'GET',
    headers: jwtHeaders(),
    redirect: 'follow',
  });
  if (response?.status === 401) {
    logoutUtil();
  }
  const resData = await response?.json();

  return resData;
};
export const createClient = async (data) => {
  const response = await fetch(`${baseUrl}/api/client/create`, {
    method: 'POST',
    body: data,
    // headers: headers,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const fetchClientByNumber = async (clientNumber, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/client/getclientbynumber/${branchId}?clientNumber=${clientNumber}`,
    {
      method: 'GET',
      headers:jwtHeaders()
    },
  );
  const resData = await response?.json();
  return resData;
};

export const fetchClientById = async (id, branchId) => {
  const response = await fetch(`${baseUrl}/api/client/getclient/${id}${branchId ? branchId : ''}`, {
    method: 'GET',
    headers:jwtHeaders()
  });
  const resData = await response?.json();
  return resData;
};

export const fetchBillByClientNumber = async (clientNumber, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/billing/getclientbill/${clientNumber}${branchId ? branchId : ''}`,
    {
      method: 'GET',
      headers:jwtHeaders()
    },
  );
  const resData = await response?.json();
  return resData;
};

export const updateClient = async (id, data, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/client/updateclient/${id}${branchId ? branchId : ''}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      // headers: headers,
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};

export const sendBulkSms = async (data) => {
  const response = await fetch(`${baseUrl}/api/client/bulksms`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: jwtHeaders(),
  });
  const resData = await response.json();
  return resData;
};

export const deleteClient = async (id, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/client/deleteclient/${id}${branchId ? branchId : ''}`,
    {
      method: 'DELETE',
      // headers: headers,
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};

export const createManyClient = async (id, data) => {
  const response = await fetch(`${baseUrl}/api/client/createmanyclient/${id}`, {
    method: 'POST',
    body: data,
    // headers: headers,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const buyMembership = async (branchId, data) => {
  const response = await fetch(`${baseUrl}/api/client/buymembership/${branchId}`, {
    method: 'POST',
    body: JSON.stringify(data),
    // headers,
    headers:jwtHeaders(),
  });
  const resData = await response?.json();
  return resData;
};

export const fetchVisitedClinetAnalytics = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/client/visitedclientanalysis/${branchId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      // headers,
      headers:jwtHeaders(),
      redirect: 'follow',
    },
  );
  const resData = await response?.json();
  return resData;
};

export const getClientReviews = async (queryParams) => {
  const response = await fetch(`${baseUrl}/api/client/clientreviews?${queryParams}`, {
    method: 'GET',
    headers:jwtHeaders(),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const resData = await response.json();
  return resData;
};

export const walletPayment = async(branchId,data) =>{
  const response = await fetch(`${baseUrl}/api/billing/updatewallet?branchId=${branchId}`,{
    method: 'POST',
    body: JSON.stringify(data),
    headers: jwtHeaders(),
  });
  const resData = await response.json();
  return resData;
};