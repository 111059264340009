import { baseUrl, headers, jwtHeaders } from '../common/data';

export const fetchAllServices = async (branchId, queryParams) => {
  const response = await fetch(
    `${baseUrl}/api/service/getallservices/${branchId}${queryParams ? queryParams : ''}`,
    {
      method: 'GET',
      // headers: headers,
      headers: jwtHeaders(),
      redirect: 'follow',
    },
  );
  const resData = await response?.json();

  return resData;
};

export const getAllService = async (branchId) => {
  const response = await fetch(`${baseUrl}/api/service/getAll/${branchId}`, {
    method: 'GET',
    // headers: headers,
    headers: jwtHeaders(),
    redirect: 'follow',
  });
  const resData = await response?.json();

  return resData;
};

export const createService = async (data) => {
  const response = await fetch(`${baseUrl}/api/service/createservice`, {
    method: 'POST',
    body: data,
    // headers: headers,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();

  return resData;
};

export const updateService = async (id, data, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/service/updateservice/${id}${branchId ? branchId : ''}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      // headers: headers,
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();

  return resData;
};
export const deleteService = async (id, branchId) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/service/deleteservice/${id}${branchId ? branchId : ''}`,
      {
        method: 'DELETE',
        // headers: headers,
        headers: jwtHeaders(),
      },
    );
    const resData = await response?.json();
    return resData;
  } catch (err) {}
};

export const createManyService = async (id, data) => {
  const response = await fetch(`${baseUrl}/api/service/createmanyservice/${id}`, {
    method: 'POST',
    body: JSON.stringify(data),
    // headers: headers,
    headers: jwtHeaders(),
  });
  const resData = await response?.json();
  return resData;
};

export const deleteManyService = async (data, branchId) => {
  const response = await fetch(
    `${baseUrl}/api/service/deletemanyservices${branchId ? branchId : ''}`,
    {
      method: 'POST',
      body: JSON.stringify(data),
      // headers,
      headers: jwtHeaders(),
    },
  );
  const resData = await response?.json();
  return resData;
};
