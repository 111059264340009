import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';

import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
import Coupons from './Coupons';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import SearchImg from '../../assets/search.svg';
import DashboardCard2 from 'src/components/shared/DashboardCard2';

const AllCoupons = () => {
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const { fetchAllCoupon, activeBranchId } = useMain();
  const [filteredData, setFilteredData] = useState([]);

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    if (clear) {
      getData('clear');
      setClear(false);
    }
  };

  const onApplyHandler = () => {
    if (search) {
      getData();
      setClear(true);
    }
  };

  const getData = async (key) => {
    let queryParams = `?page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`;

    if (key) queryParams = `?page=${page}&limit=${limit}`;

    try {
      setShowLoader(true);
      const res = await fetchAllCoupon(activeBranchId);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      } else {
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      setClear(true);
      getData();
    }
    return () => {
      setData([]);
    };
  }, [activeBranchId]);


  useEffect(() => {
    applyFilters();
  }, [limit, page, search, data]);

  const applyFilters = () => {
    let filteredData = data?.filter((item) => {
      let isNameMatch = true;
      if (search) {
        isNameMatch = item?.couponCode?.toLowerCase().includes(search.toLowerCase());
      }

      return isNameMatch;
    });

    // Applying pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilteredData(filteredData);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  return (
    <PageContainer title="All Coupons" description="this contains all coupons">
      <DashboardCard2 title="All Coupons">
        <Grid container style={{ gap: '10px' }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={2}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                }}
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={1.3} marginRight={-13}>
              <div>
                <InputLabel>Search</InputLabel>
                <div className="search-input">
                  <img src={SearchImg} alt="" />
                  <input
                    type="text"
                    placeholder=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
              {/* <Button
                variant="contained"
                color="primary"
                target="_blank"
                sx={{
                  background: '#6174DD',
                  height: '40px',
                  width: '100%',
                }}
                onClick={onApplyHandler}
              >
                Apply
              </Button> */}

              <Button
                variant="contained"
                color="primary"
                target="_blank"
                sx={{
                  height: '40px',
                  // width: '100%',
                  width: '60%',
                  marginLeft:'5rem',
                  background: '#ff4d4d',
                  '&:hover': {
                    background: '#ff6666',
                  },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {showLoader ? (
          <ShowLoader />
        ) : filteredData && filteredData.length > 0 ? (
          <>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Coupons data={filteredData} setData={setData} getCoupons={getData} />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          'No data found'
        )}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            // count={data.length < limit ? page : page + 1}
            count={filteredData && filteredData.length < limit ? page : page + 1}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllCoupons;
