import { baseUrl, headers, jwtHeaders } from '../common/data';

export const fetchAllProducts = async (branchId,queryParams) => {
  const response = await fetch(`${baseUrl}/api/product/getproductbybranch/${branchId}${queryParams?queryParams:''}`, {
    method: 'GET',
    // headers: headers,
    headers: jwtHeaders(),
    redirect: 'follow',
  });
  const resData = await response?.json();
  
  return resData;
};

export const createProduct = async (data) => {

    const response = await fetch(`${baseUrl}/api/product/create`, {
      method: 'POST',
      body: data,
      // headers: headers,
      headers: jwtHeaders(),
    });
    const resData = await response?.json();
    
    return resData;
};

export const createManyProduct = async (branchId, data) => {
  const response = await fetch(`${baseUrl}/api/product/bulkUpload/${branchId}`, {
    method: 'POST',
    body:JSON.stringify(data),
    // headers
    headers: jwtHeaders(),
  });
  const resData = await response?.json()

  return resData
};

export const updateProduct = async (id, data,branchId) => {

    const response = await fetch(`${baseUrl}/api/product/updateproduct/${id}${branchId ? branchId : ''}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      // headers,
      headers: jwtHeaders(),
    });
    const resData = await response?.json();
    
    return resData;

};

export const deleteProduct = async (id,branchId) => {

    const response = await fetch(`${baseUrl}/api/product/deleteproduct/${id}${branchId ? branchId : ''}`, {
      method: 'DELETE',
      // headers: headers,
      headers: jwtHeaders(),
    });
    const resData = await response?.json();
    
    return resData;
};

export const deleteManyProduct=async(data,branchId)=>{
  const response = await fetch(`${baseUrl}/api/product/deletemanyproducts${branchId ? branchId : ''}`,{
    method:'POST',
    body: JSON.stringify(data),
    // headers
    headers: jwtHeaders(),
  })
  const resData = await response.json()
  return resData
}