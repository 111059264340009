import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import * as XLSX from 'xlsx';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DateRangeInputs from 'src/components/inputs/DateRangeInputs';
import FieldData from './FieldData';
import SearchImg from '../../assets/search.svg';
import { useMain } from '../hooks/useMain';
import { useNavigate } from 'react-router';

const filterGenderWise = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
];

const AllBillings = () => {
  const [data, setData] = useState([]);

  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [genderBy, setGenderBy] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [billType, setBillType] = useState('Bill');

  const { fetchAllBillings, activeBranchId, fetchPackageBills } = useMain();
  //console.log(data);

  const navigate = useNavigate();
  useEffect(() => {
    applyFilters(data, limit, genderBy, page, search, startDate, endDate);
  }, [data, limit, genderBy, page, search, startDate, endDate]);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDateRangeSelected = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsModalOpen(false);
    const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''
      }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;
    getData(queryParams);
    setClear(true);
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setGenderBy('');
    setEndDate('');
    setStartDate('');
    if (clear) {
      const queryParams = `?page=${page}&limit=${limit}`;
      getData(queryParams);
      setClear(false);
    }
  };

  const onApplyHandler = () => {
    if (search) {
      const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''
        }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;
      getData(queryParams);
      setClear(true);
    }
  };

  const getData = async () => {
    const queryParams = `?limit=10000&billType=${billType}`;
    try {
      setShowLoader(true);
      const res = await fetchAllBillings(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (activeBranchId) {
      const queryParams = `?page=${page}&limit=${limit}${genderBy ? `&gender=${genderBy}` : ''}${search ? `&search=${search}` : ''
        }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`;
      getData(queryParams, billType);
      setClear(true);
    }
    return () => {
      setData([]);
    };
  }, [activeBranchId, billType]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const handleOnExport = () => {
    // Check if data is defined and is an array
    if (!Array.isArray(data)) {
      console.error('Data is not an array or is undefined');
      return;
    }

    // Flatten the data and extract fields from the services array
    const flattenedData = data.map((item) => {
      const flattenedItem = { ...item };

      if (Array.isArray(item.services)) {
        flattenedItem.serviceName = item.services
          .map((serviceItem) => serviceItem.serviceName)
          .join(', ');
        flattenedItem.serviceProviders = item.services
          .map((serviceItem) => serviceItem.serviceProvider?.name)
          .join(', ');
      }
      if (Array.isArray(item.products)) {
        flattenedItem.productName = item.products
          .map((productItem) => productItem.productName)
          .join(', ');
        flattenedItem.productProvider = item.products
          .map((productItem) => productItem.providerStaff?.name)
          .join(', ');
      }
      return flattenedItem;
    });

    // Convert the flattened data to a sheet
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(flattenedData);
    XLSX.utils.book_append_sheet(wb, ws, 'Bills');
    XLSX.writeFile(wb, 'Bills.xlsx');
  };

  const applyFilters = (data, limit, gender, page, search, startDate, endDate) => {
    let filteredData = data.filter((item) => {
      let isGenderMatch = true;
      let isNameMatch = true;
      let isDateMatch = true;

      if (gender) {
        isGenderMatch = item.gender === gender;
      }

      if (search) {
        const clientName = item.clientName || '';
        const clientNumber = item.clientNumber || '';
        isNameMatch =
          clientName.toLowerCase().includes(search.toLowerCase()) || clientNumber.includes(search);
      }

      if (startDate && endDate) {
        const billDate = new Date(item.dateOfBilling);
        isDateMatch = billDate >= new Date(startDate) && billDate <= new Date(endDate);
      }

      return isGenderMatch && isNameMatch && isDateMatch;
    });

    // Applying pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filteredData = filteredData.slice(startIndex, endIndex);
    setFilterData(filteredData);
  };

  console.log(filterData, 'filterDataaaaaaa');

  return (
    <PageContainer title="Billing History" description="this contains all products">
      <DashboardCard2 title="Billing History">
        {/* <Grid container style={{ gap: '10px' }}> */}
          <Grid container justifyContent="space-between">
            {/* Show per page */}
            <Grid item xs={2}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                }}
                value={limit}
                onChange={handleLimitChange}
              >
                {[10, 20, 50].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Date Range */}
            <Grid item xs={2} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} marginRight={-10}>
              <Typography variant="subtitle2" fontWeight={500} component="label">
                {startDate && endDate ? `${startDate} to ${endDate}` : ' '}
              </Typography>
              <Button
                variant="outlined"
                target="_blank"
                sx={{ color: 'black', height: '40px', width: '100%' }}
                onClick={openModal}
              >
                Choose Date
              </Button>
            </Grid>

            {/* Gender Filter */}
            <Grid item xs={1.3} marginRight={-10}>
              <div>
                <InputLabel>Gender</InputLabel>
                <Select
                  style={{
                    borderRadius: '7px',
                    border: '0.5px solid #6174DD',
                    padding: '4px 12px',
                    background: '#FFF',
                    height: '40px',
                    width: '100%',
                  }}
                  value={genderBy}
                  onChange={(e) => setGenderBy(e.target.value)}
                >
                  {filterGenderWise.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>

            {/* Search */}
            <Grid item xs={1.3} marginRight={-30}>
              <div>
                <InputLabel>Search</InputLabel>
                <div className="search-input">
                  <img src={SearchImg} alt="" />
                  <input
                    type="text"
                    placeholder=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </Grid>

            {/* Apply and Clear Buttons */}
            <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  // width: '100%',
                  width: '60%',
                  marginLeft: '9rem',
                  background: '#ff4d4d',
                  '&:hover': { background: '#ff6666' },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </Grid>
            <Grid item justifyContent="center" marginTop={3.4} marginLeft={-10}>
              {!showLoader && data.length > 0 && (
                <Button
                  variant="contained"
                  fullWidth
                  target="_blank"
                  color="primary"
                  sx={{ padding: '0.5rem', '&:hover': { background: '#6174DD' } }}
                  onClick={handleOnExport}
                >
                  Download Bills
                </Button>
              )}
            </Grid>
          </Grid>

          {/* Package Bills Button */}
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "1rem", gap:"24rem" }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                // fullWidth
                target="_blank"
                color="primary"
                sx={{ '&:hover': { background: '#6174DD' } }}
                onClick={() => {
                  navigate('/PackageAllBill');
                }}
              >
                Package Bills
              </Button>
            </Grid>
            <Grid item xs={6}>
              <ToggleButtonGroup
                value={billType}
                exclusive
                sx={{
                  borderRadius: '4px',
                  height: '40px',
                  backgroundColor: '#f0f0f0',
                  width: '150px',
                  borderRadius: '30px',
                  border: "1px solid blue",
                }}
                onChange={(event, newBillType) => {
                  if (newBillType) {
                    setBillType(newBillType);
                    setPage(1);
                  }
                }}
              >
                <ToggleButton
                  value="Bill"
                  sx={{
                    color: '#000',
                    backgroundColor: '#e0e0e0',
                    width: '75px',
                    borderRadius: '30px',
                    '&.Mui-selected': {
                      backgroundColor: '#6174DD',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#6174DD',
                      },
                    },
                    '&:not(.Mui-selected)': {
                      '&:hover': {
                        backgroundColor: '#d0d0d0',
                      },
                    },
                  }}
                >
                  Bills
                </ToggleButton>
                <ToggleButton
                  value="Wallet"
                  sx={{
                    color: '#000',
                    backgroundColor: '#e0e0e0',
                    width: '75px',
                    borderRadius: '30px',
                    '&.Mui-selected': {
                      backgroundColor: '#6174DD',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#6174DD',
                      },
                    },
                    '&:not(.Mui-selected)': {
                      '&:hover': {
                        backgroundColor: '#d0d0d0',
                      },
                    },
                  }}
                >
                  Wallet
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                // fullWidth
                color="primary"
                sx={{  marginleft: "-10rem", margintop: "1rem",'&:hover': { background: '#6174DD' } }}
                onClick={() => navigate('/AllDraftBills')}
              >
               All Draft Bills
              </Button>
            </Grid>
          </div>
        {/* </Grid> */}

        {/* Show Loader or Data */}
        {showLoader ? (
          <ShowLoader />
        ) : filterData?.length > 0 ? (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <FieldData data={filterData} setData={setData} getData={getData} billType={billType} />
              </Grid>
            </Grid>
          </Box>
        ) : (
          'No data found'
        )}

        {/* Pagination */}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            // count={data.length < limit ? page : page + 1}
            count={filterData && filterData?.length < limit ? page : page + 1}
            a
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>

        {/* Date Range Inputs */}
        <DateRangeInputs
          isOpen={isModalOpen}
          onClose={closeModal}
          onDateRangeSelected={handleDateRangeSelected}
          selectedEndDate={endDate}
          selectedStartDate={startDate}
        />
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllBillings;
