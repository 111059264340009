import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Grid,
  FormControl,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom'; // Import the Link component from React Router
import TableCells from 'src/components/Tables/TableCells';
import RadioInputs from 'src/components/inputs/RadioInputs';
import TextInputs from 'src/components/inputs/TextInputs';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useNavigate } from 'react-router';
import SelectInputs from 'src/components/inputs/SelectInputs';
import TimeSlotSelector from 'src/components/inputs/TimeSlotSelector';

// const TrimData = (data) => {
//   if (data?.length < 25) return data;
//   return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
// };

const initialValue = {
  clientName: '',
  clientNumber: '',
  dateOfAppointment: '',
  sourceOfAppointment: '',
  timeOfAppointment: '',
  gender: '',
  serviceSelected: [
    {
      service: '',
      serviceProvider: '',
    },
  ],
};

const sourceOptions = ['Walk In', 'Existing', 'Phone Call', 'Facebook'];

export const maskData = (value, isMasked) => {
  if (isMasked) {
    const maskLength = Math.ceil(value.length / 2);
    return '*'.repeat(maskLength) + value.substring(maskLength);
  } else {
    return value;
  }
};

const Appointments = ({ data, setData, getData }) => {
  const [updateData, setUpdateData] = useState(initialValue);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [openDialogue, setOpenDialogue] = useState(false);
  const [services, setServices] = useState([]);
  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateMask, setUpdateMask] = useState(false);
  console.log(data, "dataAppointment")

  const navigate = useNavigate();


  const {
    activeBranchId,
    getBranch,
    setShowMessage,
    updateAppointment,
    deleteAppointment,
    branchData,
  } = useMain();

  useEffect(() => {
    setUpdateMask(!updateMask)
  }, [branchData?.isMasked])

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };
  const handleUpdate = (item) => {
    console.log(item, 'item')
    setSelectedItemId(item._id);
    let {
      clientName,
      clientNumber,
      gender,
      serviceSelected,
      sourceOfAppointment,
      dateOfAppointment,
      timeOfAppointment,
    } = item;
    console.log(serviceSelected, "serviceSelected")
    serviceSelected = serviceSelected.map((item) => ({
      service: item.service,
      serviceProvider: item.serviceProvider,
    }));
    setUpdateData((pre) => ({
      ...pre,
      clientName,
      clientNumber,
      dateOfAppointment,
      sourceOfAppointment,
      timeOfAppointment,
      gender,
      serviceSelected,
    }));
    setOpenDialogue(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((pre) => ({ ...pre, [name]: value }));
  };


  // const handleServiceSelectedInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedServiceSelected = [...updateData.serviceSelected];
  //   updatedServiceSelected[index][name] = value;
  //   setUpdateData((pre) => ({ ...pre, serviceSelected: updatedServiceSelected }));
  // };

  const handleServiceSelectedInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedServices = [...updateData.serviceSelected];
  
    if (name === 'service') {
      updatedServices[index].service = services.find((service) => service._id === value);
    } else if (name === 'serviceProvider') {
      updatedServices[index].serviceProvider = staff.find((provider) => provider._id === value);
    }
    setUpdateData({
      ...updateData,
      serviceSelected: updatedServices,
    });
  };
  

  const addRow = () => {
    const isUnFillField = updateData.serviceSelected.some(
      (item) => item.serviceProvider === '' || item.service === '',
    );
    if (isUnFillField)
      return setShowMessage({
        message: 'Please select a staff and services',
        messageType: 'error',
      });
    setUpdateData((pre) => ({
      ...pre,
      serviceSelected: [...pre.serviceSelected, { service: '', serviceProvider: '' }],
    }));
  };

  const removeRow = (index) => {
    const updatedServiceSelected = [...updateData.serviceSelected];
    updatedServiceSelected.splice(index, 1);
    setUpdateData((pre) => ({ ...pre, serviceSelected: updatedServiceSelected }));
  };

  const handleUpdateAppointmentData = async () => {
    const {
      clientName,
      gender,
      clientNumber,
      timeOfAppointment,
      dateOfAppointment,
      sourceOfAppointment,
    } = updateData;
    if (clientName.trim() === '')
      return setShowMessage({ message: 'Client Name is Required', messageType: 'error' });
    if (timeOfAppointment === '')
      return setShowMessage({ message: 'Time of Appointment is Required', messageType: 'error' });
    if (clientNumber === '')
      return setShowMessage({ message: 'Client Number is Required', messageType: 'error' });
    if (dateOfAppointment === '')
      return setShowMessage({ message: 'Appointment Date is Required', messageType: 'error' });
    if (sourceOfAppointment === '')
      return setShowMessage({ message: 'Source is Required', messageType: 'error' });
    if (gender === '')
      return setShowMessage({ message: 'Gender is Required', messageType: 'error' });
    const isUnFillField = updateData.serviceSelected.some(
      (item) => item.serviceProvider === '' || item.service === '',
    );
    if (isUnFillField)
      return setShowMessage({
        message: 'Please select a staff and services',
        messageType: 'error',
      });
    try {
      setLoading(true);
      // Extract clientName and create restData without clientName
      console.log(updateData)
      const res = await updateAppointment(
        selectedItemId,
        updateData,
        `?branchId=${activeBranchId}`,
      );
      if (res.statusCode === 200) {
        setUpdateData((pre) => ({
          ...pre,
          ...initialValue,
          serviceSelected: [
            {
              service: '',
              serviceProvider: '',
            },
          ],
        }));
        getData();
        return setShowMessage({
          message: res?.message || 'Appointment updated successfully',
          messageType: 'success',
        });
      } else {
        return setShowMessage({
          message: res?.message || 'Error ouccures in update appointment',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error?.message || 'Error ouccures in update appointment',
        messageType: 'error',
      });
    } finally {
      setLoading(false);
      setOpenDialogue(false);
    }
  };

  const handleDeleteField = async (id) => {
    try {
      const res = await deleteAppointment(id, `?branchId=${activeBranchId}`);
      if (res?.statusCode === 200) {
        setData(data?.filter((data) => data?._id !== id));
        setShowMessage({ message: 'Appointment deleted successfully', messageType: 'success' });
      } else {
        setShowMessage({
          message: res?.message || 'Error ouccures in Update appointment',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({
        message: error?.message || 'Error ouccures in Update appointment',
        messageType: 'error',
      });
    }
  };

  const getAllData = async () => {
    try {
      const res = await getBranch(activeBranchId);
      setStaff(res?.data?.staffs || []);
      setServices(res?.data?.services || []);
    } catch (error) { }
  };

  useEffect(() => {
    if (activeBranchId) {
      getAllData();
    }
  }, [activeBranchId]);
  console.log(services, "services")
  console.log(staff, "staff")

  const handleDetails = (id) => {
    navigate(`/appointments-history/${id}`);
  };

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            {[
              'Client Name',
              'Number',
              'Gender',
              'Date of Appointment',
              'Time of Appointment',
              'Appointment Status',
              'Source Of Appointment',
              // 'Notes',
              'Actions',
            ].map((h, index) => {
              return (
                <th key={index} style={{ width: '10%' }}>
                  {h}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => {
            return (
              <tr key={item._id}>
                <td style={{ width: '10%' }}>{item?.clientName}</td>
                <td style={{ width: '10%' }}>
                  {typeof branchData?.isMasked !== 'boolean' || !item?.clientNumber
                    ? 'loading...'
                    : maskData(item?.clientNumber, branchData?.isMasked)}
                </td>
                <td style={{ width: '10%' }}>{item?.gender}</td>
                <td style={{ width: '10%' }}>{item?.dateOfAppointment}</td>
                <td style={{ width: '10%' }}>{item?.timeOfAppointment}</td>
                <td style={{ width: '10%' }}>{item?.appointmentStatus}</td>
                <td style={{ width: '10%' }}>{item?.sourceOfAppointment}</td>
                {/* <td style={{ width: '10%' }}></td> */}
                <td style={{ width: '10%' }}>
                  <TableCells
                    type="button"
                    handleDeleteField={handleDeleteField}
                    handleUpdate={() => handleUpdate(item)}
                    handleDetails={handleDetails}
                    field={item?._id}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md" fullWidth>
        <DialogTitle>Appointment Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update appointment details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Client Number'}
                  name={'clientNumber'}
                  value={updateData?.clientNumber}
                  onChange={handleChange}
                  type={'number'}
                  required={false}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Client Name'}
                  name={'clientName'}
                  value={updateData?.clientName}
                  handleChange={handleChange}
                  type={'text'}
                  required={false}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={4}>
                <RadioInputs
                  title={'Gender'}
                  name={'gender'}
                  value={updateData?.gender}
                  handleChange={handleChange}
                  options={[
                    { title: 'M', value: 'Male' },
                    { title: 'F', value: 'Female' },
                  ]}
                  required={false}
                />
              </Grid> */}
            </Grid>
          </Box>

          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <div style={{ marginBottom: '3px', fontWeight: 'bold' }}>
                    Source Of Appointment
                  </div>
                  <Select
                    name="sourceOfAppointment"
                    // multiple
                    value={updateData.sourceOfAppointment}
                    onChange={(e) => handleChange(e)}
                  >
                    {sourceOptions?.map((sourceOption) => (
                      <MenuItem key={sourceOption} value={sourceOption}>
                        {sourceOption}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={4}>
                <TextInputs
                  title={'Date of Appointment'}
                  name={'dateOfAppointment'}
                  value={updateData?.dateOfAppointment}
                  handleChange={handleChange}
                  type={'date'}
                  min={new Date().toISOString().split('T')[0]}
                  required={false}
                />
              </Grid>
              {/* <Grid container spacing={3}> */}

              {/* </Grid> */}
              <Grid item xs={12} md={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {/* <TextInputs
                      title={'Time of Appointment'}
                      name={'timeOfAppointment'}
                      value={updateData?.timeOfAppointment}
                      handleChange={handleChange}
                      type={'time'}
                      required={false}
                    /> */}
                    <TimeSlotSelector
                      title={'Time of Appointment'}
                      name={'timeOfAppointment'}
                      value={updateData?.timeOfAppointment}
                      handleChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {updateData.serviceSelected.map((item, index) => (
            <Box style={{ marginTop: '20px' }}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} md={4}>
                  <SelectInputs
                    name={'service'}
                    value={item?.service?._id}
                    title={'Services'}
                    handleChange={(e) => handleServiceSelectedInputChange(e, index)}
                    options={services}
                    optionValue={'_id'}
                    optionTitle={'serviceName'}
                    required={false}
                    renderValue={(selected) => {
                      const selectedService = services.find(service => service._id === selected);
                      return selectedService ? selectedService.serviceName : '';
                    }}
                    renderOptions={(options) => {
                      // Remove the selected service temporarily
                      const filteredOptions = options.filter(option => option._id !== item?.service?._id);
                      // Append the selected service to the beginning of the list
                      return [
                        { _id: item?.service?._id, serviceName: item?.service?.serviceName },
                        ...filteredOptions
                      ];
                    }}
                  />
                </Grid> */}

                <Grid item xs={12} md={4}>
                  <SelectInputs
                    name={'service'}
                    value={item?.service?._id}
                    title={'Services'}
                    handleChange={(e) => handleServiceSelectedInputChange(e, index)}
                    options={services}
                    optionValue={'_id'}
                    optionTitle={'serviceName'}
                    required={false}
                    renderValue={(selected) => {
                      const selectedService = services.find(service => service._id === selected);
                      return selectedService ? selectedService.serviceName : '';
                    }}
                    renderOptions={(options) => {
                      // Remove the selected service temporarily
                      const filteredOptions = options.filter(option => option._id !== item?.service?._id);
                      // Append the selected service to the beginning of the list
                      return [
                        { _id: item?.service?._id, serviceName: item?.service?.serviceName },
                        ...filteredOptions
                      ];
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <SelectInputs
                    name={'serviceProvider'}
                    value={item?.serviceProvider?._id || ''}
                    title={'Assign to'}
                    handleChange={(e) => handleServiceSelectedInputChange(e, index)}
                    options={staff}
                    optionValue={'_id'}
                    optionTitle={'name'}
                    required={false}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '5px' }}
                >
                  <Grid item xs={12} md={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      target="_blank"
                      sx={{
                        background: '#6174DD',
                        width: '64px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                      onClick={addRow}
                    >
                      Add
                    </Button>
                  </Grid>
                  {index !== 0 ? (
                    <Grid item xs={12} md={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        sx={{
                          background: '#ff0000',
                          width: '64px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          '&:hover': {
                            background: '#ff6666', // Change the background color on hover
                          },
                        }}
                        onClick={() => removeRow(index)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader value={loading} />
          ) : (
            <>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  target="_blank"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleUpdateAppointmentData}
                >
                  update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Appointments;

function getCurrentDateInFormat(date) {
  const today = new Date(date);
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
