// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import ServiceTypeFieldData from './ServiceTypeFieldData';
// import DiscountTypeFieldData from './DiscountTypeFieldData';
// import SearchImg from '../../assets/search.svg';
// import { useLocation } from 'react-router';
// import AllPackages from './OurPackages/AllPackages';

// const AllMemberships = () => {
//   const [data, setData] = useState([]);
//   const [membershipType, setMembershipType] = useState('serviceType');
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [clear, setClear] = useState(false);
//   const [filterData, setFilterData] = useState([]);
//   const [packageData, setPackageData] = useState([]);
//   const [packageFilterData, setPackageFilterData] = useState([]);
//   const { fetchAllMemberships, activeBranchId, fetchAllPackages } = useMain();

//   const { state } = useLocation();

//   const handleMembershipTypeChange = (type) => {
//     setSearch('');
//     setLimit(10);
//     setPage(1);
//     setMembershipType(type);
//   };

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     if (clear) {
//       getData('clear');
//       setPackageData([]);
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async (key) => {
//     let queryParams = `?membershipType=${membershipType}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`;
//     if (key) queryParams = `?membershipType=${membershipType}&page=${page}&limit=${limit}&sortBy=createdAt`;

//     try {
//       setShowLoader(true);
//       const res = await fetchAllMemberships(activeBranchId, queryParams);
//       if (res.statusCode === 200) {
//         setData(res?.data || []);
//       } else {
//         setData([]);
//       }
//     } catch (error) {
//       setData([]);
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   const getPackageData = async () => {
//     try {
//       setShowLoader(true);
//       const res = await fetchAllPackages(activeBranchId);
//       if (res.statusCode === 200) {
//         setPackageData(res?.data || []);
//       } else {
//         setPackageData([]);
//       }
//     } catch (error) {
//       setPackageData([]);
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       getPackageData();
//     }
//     return () => {
//       setPackageData([]);
//     };
//   }, [activeBranchId]);

//   useEffect(() => {
//     if (activeBranchId) {
//       setClear(true);
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId, limit, page, membershipType]);

//   useEffect(() => {
//     applyFilters();
//   }, [data, limit, page, search]);

//   useEffect(() => {
//     applyPackageFilters();
//   }, [packageData, limit, page, search]);

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   const applyFilters = () => {
//     let filteredData = data.filter((item) => {
//       let isNameMatch = true;
//       if (search) {
//         isNameMatch = item.membershipName.toLowerCase().includes(search.toLowerCase());
//       }
//       return isNameMatch;
//     });

//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     setFilterData(filteredData.slice(startIndex, endIndex));
//   };

//   const applyPackageFilters = () => {
//     let filteredData = packageData.filter((item) => {
//       let isNameMatch = true;
//       if (search) {
//         isNameMatch = item.packageName.toLowerCase().includes(search.toLowerCase());
//       }
//       return isNameMatch;
//     });

//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     setPackageFilterData(filteredData.slice(startIndex, endIndex));
//   };

//   const totalItems = membershipType === 'serviceType' || membershipType === 'discountType' ? data.length : packageData.length;
//   const totalPages = Math.ceil(totalItems / limit);

//   return (
//     <PageContainer title="Manage Membership" description="this contains all products">
//       <DashboardCard2 title="Manage Membership">
//         <Grid container style={{ gap: '10px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={2}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={2}></Grid>
//             <Grid item xs={2}></Grid>
//             <Grid item xs={2}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => {
//                       setSearch(e.target.value);
//                       setPage(1); // Reset to first page when search changes
//                     }}
//                   />
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>
//           <Grid item xs={12} container>
//             <Button
//               variant="contained"
//               // color={membershipType ? 'primary' : 'secondary'}
//               onClick={() => handleMembershipTypeChange('discountType')}
//               style={{
//                 marginRight: '16px',
//                 border: membershipType === 'discountType' ? '3px solid #FFD700' : 'none',
//               }}
//             >
//               Discount Membership
//             </Button>
//             <Button
//               variant="contained"
//               // color={membershipType === 'serviceType' ? 'primary' : 'secondary'}
//               onClick={() => handleMembershipTypeChange('serviceType')}
//               style={{ border: membershipType === 'serviceType' ? '3px solid #FFD700' : 'none' }}
//             >
//               Service Membership
//             </Button>
//             <Button
//               variant="contained"
//               // color={membershipType === 'packageType' ? 'primary' : 'secondary'}
//               onClick={() => handleMembershipTypeChange('packageType')}
//               style={{ border: membershipType === 'packageType' ? '3px solid #FFD700' : 'none', marginLeft: '1rem' }}
//             >
//               All Packages
//             </Button>
//           </Grid>
//         </Grid>

//         {showLoader ? (
//           <ShowLoader />
//         ) : membershipType === 'serviceType' ? (
//           filterData.length > 0 ? (
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <ServiceTypeFieldData
//                     data={filterData}
//                     setData={setData}
//                     getData={getData}
//                     state={state}
//                   />
//                 </Grid>
//               </Grid>
//             </Box>
//           ) : (
//             'No data found'
//           )
//         ) : membershipType === 'discountType' ? (
//           filterData.length > 0 ? (
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <DiscountTypeFieldData
//                     data={filterData}
//                     setData={setData}
//                     getData={getData}
//                     state={state}
//                   />
//                 </Grid>
//               </Grid>
//             </Box>
//           ) : (
//             'No data found'
//           )
//         ) : packageFilterData.length > 0 ? (
//           <Box>
//             <Grid container spacing={3}>
//               <Grid item xs={12} lg={12}>
//                 <AllPackages
//                   data={packageFilterData}
//                   setPackageData={setPackageData}
//                   getPackageData={getPackageData}
//                 />
//               </Grid>
//             </Grid>
//           </Box>
//         ) : (
//           'No data found'
//         )}

//         {totalPages > 1 && (
//           <Box display={'flex'} justifyContent={'flex-end'}>
//             <Pagination
//               count={totalPages}
//               page={page}
//               color="primary"
//               onChange={(e, page) => setPage(page)}
//             />
//           </Box>
//         )}
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllMemberships;

//active button code
// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import ServiceTypeFieldData from './ServiceTypeFieldData';
// import DiscountTypeFieldData from './DiscountTypeFieldData';
// import SearchImg from '../../assets/search.svg';
// import { useLocation } from 'react-router';
// import AllPackages from './OurPackages/AllPackages';

// const AllMemberships = () => {
//   const [data, setData] = useState([]);
//   const [membershipType, setMembershipType] = useState('serviceType');
//   const [showLoader, setShowLoader] = useState(true);
//   const [search, setSearch] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [clear, setClear] = useState(false);
//   const [filterData, setFilterData] = useState([]);
//   const [packageData, setPackageData] = useState([]);
//   const [packageFilterData, setPackageFilterData] = useState([]);
//   const { fetchAllMemberships, activeBranchId, fetchAllPackages } = useMain();

//   const { state } = useLocation();

//   const handleMembershipTypeChange = (type) => {
//     setSearch('');
//     setLimit(10);
//     setPage(1);
//     setMembershipType(type);
//   };

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     if (clear) {
//       getData('clear');
//       setPackageData([]);
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async (key) => {
//     let queryParams = `?membershipType=${membershipType}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`;
//     if (key) queryParams = `?membershipType=${membershipType}&page=${page}&limit=${limit}&sortBy=createdAt`;

//     try {
//       setShowLoader(true);
//       const res = await fetchAllMemberships(activeBranchId, queryParams);
//       if (res.statusCode === 200) {
//         setData(res?.data || []);
//       } else {
//         setData([]);
//       }
//     } catch (error) {
//       setData([]);
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   const getPackageData = async () => {
//     try {
//       setShowLoader(true);
//       const res = await fetchAllPackages(activeBranchId);
//       if (res.statusCode === 200) {
//         setPackageData(res?.data || []);
//       } else {
//         setPackageData([]);
//       }
//     } catch (error) {
//       setPackageData([]);
//     } finally {
//       setShowLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (activeBranchId) {
//       getPackageData();
//     }
//     return () => {
//       setPackageData([]);
//     };
//   }, [activeBranchId]);

//   useEffect(() => {
//     if (activeBranchId) {
//       setClear(true);
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [activeBranchId, limit, page, membershipType]);

//   useEffect(() => {
//     applyFilters();
//   }, [data, limit, page, search]);

//   useEffect(() => {
//     applyPackageFilters();
//   }, [packageData, limit, page, search]);

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   const applyFilters = () => {
//     let filteredData = data.filter((item) => {
//       let isNameMatch = true;
//       if (search) {
//         isNameMatch = item.membershipName.toLowerCase().includes(search.toLowerCase());
//       }
//       return isNameMatch;
//     });

//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     setFilterData(filteredData.slice(startIndex, endIndex));
//   };

//   const applyPackageFilters = () => {
//     let filteredData = packageData.filter((item) => {
//       let isNameMatch = true;
//       if (search) {
//         isNameMatch = item.packageName.toLowerCase().includes(search.toLowerCase());
//       }
//       return isNameMatch;
//     });

//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     setPackageFilterData(filteredData.slice(startIndex, endIndex));
//   };

//   const totalItems = membershipType === 'serviceType' || membershipType === 'discountType' ? data.length : packageData.length;
//   const totalPages = Math.ceil(totalItems / limit);

//   return (
//     <PageContainer title="Manage Membership" description="this contains all products">
//       <DashboardCard2 title="Manage Membership">
//         <Grid container style={{ gap: '10px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={2}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={2}></Grid>
//             <Grid item xs={2}></Grid>
//             <Grid item xs={2}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => {
//                       setSearch(e.target.value);
//                       setPage(1); // Reset to first page when search changes
//                     }}
//                   />
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>
//           <Grid item xs={12} container>
//             <Button
//               variant="contained"
//               onClick={() => handleMembershipTypeChange('discountType')}
//               className={`button-64 ${membershipType === 'discountType' ? 'button-64-active' : ''}`}
//               style={{
//                 marginRight: '16px',
//               }}
//             >
//               Discount Membership
//             </Button>
//             <Button
//               variant="contained"
//               onClick={() => handleMembershipTypeChange('serviceType')}
//               className={`button-64 ${membershipType === 'serviceType' ? 'button-64-active' : ''}`}
//               style={{
//                 marginRight: '16px',
//               }}
//             >
//               Service Membership
//             </Button>
//             <Button
//               variant="contained"
//               onClick={() => handleMembershipTypeChange('packageType')}
//               className={`button-64 ${membershipType === 'packageType' ? 'button-64-active' : ''}`}
//               // style={{ marginLeft: '1rem' }}
//               style={{ marginRight: '16px' }}
//             >
//               All Packages
//             </Button>
//           </Grid>
//         </Grid>

//         {showLoader ? (
//           <ShowLoader />
//         ) : membershipType === 'serviceType' ? (
//           filterData.length > 0 ? (
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <ServiceTypeFieldData
//                     data={filterData}
//                     setData={setData}
//                     getData={getData}
//                     state={state}
//                   />
//                 </Grid>
//               </Grid>
//             </Box>
//           ) : (
//             'No data found'
//           )
//         ) : membershipType === 'discountType' ? (
//           filterData.length > 0 ? (
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <DiscountTypeFieldData
//                     data={filterData}
//                     setData={setData}
//                     getData={getData}
//                     state={state}
//                   />
//                 </Grid>
//               </Grid>
//             </Box>
//           ) : (
//             'No data found'
//           )
//         ) : packageFilterData.length > 0 ? (
//           <Box>
//             <Grid container spacing={3}>
//               <Grid item xs={12} lg={12}>
//                 <AllPackages data={packageFilterData} />
//               </Grid>
//             </Grid>
//           </Box>
//         ) : (
//           'No data found'
//         )}

//         <Grid container justifyContent="end">
//           <Pagination
//             count={totalPages}
//             color="primary"
//             page={page}
//             onChange={(e, value) => setPage(value)}
//           />
//         </Grid>
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllMemberships;

//correct pagination
import React, { useEffect, useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import ServiceTypeFieldData from './ServiceTypeFieldData';
import DiscountTypeFieldData from './DiscountTypeFieldData';
import SearchImg from '../../assets/search.svg';
import AllPackages from './OurPackages/AllPackages';
import { useLocation } from 'react-router';

const AllMemberships = () => {
  const [data, setData] = useState([]);
  const [membershipType, setMembershipType] = useState('serviceType'); // Default membership type
  const [showLoader, setShowLoader] = useState(true);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState([]);
  const [packageData, setPackageData] = useState([]);
  const [packageFilterData, setPackageFilterData] = useState([]);
  const { fetchAllMemberships, activeBranchId, fetchAllPackages } = useMain();

  const { state } = useLocation();

  // Fetch data based on membership type
  const fetchData = async (type) => {
    let queryParams = `?membershipType=${type}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`;

    try {
      setShowLoader(true);
      const res = await fetchAllMemberships(activeBranchId, queryParams);
      if (res.statusCode === 200) {
        setData(res?.data || []);
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
    } finally {
      setShowLoader(false);
    }
  };

  // Fetch package data
  const fetchPackageData = async () => {
    try {
      setShowLoader(true);
      const res = await fetchAllPackages(activeBranchId);
      if (res.statusCode === 200) {
        setPackageData(res?.data || []);
      } else {
        setPackageData([]);
      }
    } catch (error) {
      setPackageData([]);
    } finally {
      setShowLoader(false);
    }
  };

  // Fetch initial data for default membership type
  useEffect(() => {
    if (activeBranchId) {
      fetchPackageData(); // Fetch package data on mount
      fetchData('serviceType'); // Fetch default serviceType data
    }
  }, [activeBranchId]);

  useEffect(() => {
    if (membershipType === 'serviceType' || membershipType === 'discountType') {
      applyFilters();
    } else if (membershipType === 'packageType') {
      applyPackageFilters();
    }
  }, [data, packageData, limit, page, search]);

  // Handle membership type change
  const handleMembershipTypeChange = (type) => {
    setMembershipType(type);
    setSearch('');
    setLimit(10);
    setPage(1);
    if (type === 'packageType') {
      fetchPackageData();
    } else {
      fetchData(type);
    }
  };

  // Clear data
  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    fetchData(membershipType); // Re-fetch data based on current membership type
  };

  // Apply filters for memberships
  const applyFilters = () => {
    let filteredData = data.filter(item => item.membershipName.toLowerCase().includes(search.toLowerCase()));
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    setFilterData(filteredData.slice(startIndex, endIndex));
  };

  // Apply filters for packages
  const applyPackageFilters = () => {
    let filteredData = packageData.filter(item => item.packageName.toLowerCase().includes(search.toLowerCase()));
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    setPackageFilterData(filteredData.slice(startIndex, endIndex));
  };

  // Total items and pages calculation
  const totalItems = membershipType === 'packageType' ? packageData.length : data.length;
  const totalPages = Math.ceil(totalItems / limit);

  console.log(data, "dataMEMBERSHIP")

  return (
    <PageContainer title="Manage Membership" description="This contains all products">
      <DashboardCard2 title="Manage Membership">
        <Grid container style={{ gap: '10px' }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={2}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{ borderRadius: '7px', border: '0.5px solid #6174DD', padding: '4px 12px', background: '#FFF', height: '40px' }}
                value={limit}
                onChange={(e) => { setLimit(e.target.value); setPage(1); }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={1.3} marginRight={-14}>
              <div>
                <InputLabel>Search</InputLabel>
                <div className="search-input">
                  <img src={SearchImg} alt="" />
                  <input
                    type="text"
                    placeholder=""
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1); // Reset to first page when search changes
                    }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  // width: '100%',
                  width: '60%',
                  marginLeft:'5rem',
                  background: '#ff4d4d',
                  '&:hover': { background: '#ff6666' }
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Button
              variant="contained"
              onClick={() => handleMembershipTypeChange('discountType')}
              className={`button-64 ${membershipType === 'discountType' ? 'button-64-active' : ''}`}
              style={{ marginRight: '16px' }}
            >
              Discount Membership
            </Button>
            <Button
              variant="contained"
              onClick={() => handleMembershipTypeChange('serviceType')}
              className={`button-64 ${membershipType === 'serviceType' ? 'button-64-active' : ''}`}
              style={{ marginRight: '16px' }}
            >
              Service Membership
            </Button>
            <Button
              variant="contained"
              onClick={() => handleMembershipTypeChange('packageType')}
              className={`button-64 ${membershipType === 'packageType' ? 'button-64-active' : ''}`}
              style={{ marginRight: '16px' }}
            >
              All Packages
            </Button>
          </Grid>
        </Grid>

        {showLoader ? (
          <ShowLoader />
        ) : membershipType === 'serviceType' ? (
          filterData.length > 0 ? (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <ServiceTypeFieldData data={filterData} setData={setData} getData={() => fetchData('serviceType')} state={state} />
                </Grid>
              </Grid>
            </Box>
          ) : (
            'No data found'
          )
        ) : membershipType === 'discountType' ? (
          filterData.length > 0 ? (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <DiscountTypeFieldData data={filterData} setData={setData} getData={() => fetchData('discountType')} state={state} />
                </Grid>
              </Grid>
            </Box>
          ) : (
            'No data found'
          )
        ) : membershipType === 'packageType' ? (
          packageFilterData.length > 0 ? (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <AllPackages data={packageFilterData} />
                </Grid>
              </Grid>
            </Box>
          ) : (
            'No data found'
          )
        ) : null}

        <Grid container justifyContent="end">
          <Pagination
            count={totalPages}
            color="primary"
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        </Grid>
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllMemberships;